.question-notes-drawer{
    width: 500px;
}

.note-container{
    font-size: 13px;
    color: rgb(136, 136, 136);
    background-color: rgb(249, 249, 249);
    padding: 4px;
    border-radius: 6px;
}

.error-msg{
    font-size: 12px;
    color: rgb(218, 25, 67);
}