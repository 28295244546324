.playground-container {
    height: 82vh;
}

.playground-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 15px 0px 15px 0px;
}

.playground-navigation-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.playground-form {
    width: 350px;
}

.playground-form-container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.playground-icon {
    padding-right: 5px;
    color: rgb(185, 185, 185);
    font-size: 25px;
}

.playground-text {
    color: darkslategrey;
    font-weight: 600;
    display: inline;
}

.status-badge {
    margin-bottom: auto;
    border-radius: 5px;
    width: 80px;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    padding: 3px;
}
.ended {
    color:rgb(209, 82, 82);
    border:1px solid  rgb(209, 82, 82);
}

.active {
    color:lightgreen;;
    border:1px solid  lightgreen;;
}

.created {
    color:rgb(250, 187, 70);;
    border:1px solid  rgb(250, 187, 70);;
}

.playground-menu-button-position {
    display: inline;
}

.playground-progress-container{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row ;
    margin: auto;
}
.playground-progress-icon{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    margin: auto;
    font-size: 12px;
}

.playground-progress-divider{
    height: 1px;
    background-color: rgb(182, 182, 182);
    width: 100px;

}

.playground-created-success{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    height: 92vh;
    font-size: 12px;
}

.playground-created-check{
    font-size: 150px;
    color: rgb(0, 185, 92);
    align-self: center;
    margin-bottom: 25px;
}
.playground-font-small{
    font-size: 15px;
    font-weight: 400;
    color: grey;
    margin-bottom: 25px;
}
