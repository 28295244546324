.candidate-nav{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 15px;
    padding: 10px;
}

.candidate-form{
    width: 350px;
}

.candidate-form-container{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.candidate-icon {
    padding-right: 5px;
    color: rgb(185, 185, 185);
    font-size: 25px;
}

.candidate-text {
    color: darkslategrey;
    font-weight: 600;
    display: inline;
}

.candidate-filter-input{
    float: right;
}

.candidate-table-container{
    height: 82vh;
    padding: 10px;
}
