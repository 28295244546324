.playground-r{
    display: inline;
}

.playground-review-nav-item{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.playground-review-navigation{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left:10px;
}
.playground-room-participants{
    height: 50px;
    border-top: 1px rgb(219, 219, 219) solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
}

.notes-drawer{
    width: 500px;
}

.note-container{
    width:500px;
    font-size: 13px;
    color: rgb(136, 136, 136);
    background-color: rgb(249, 249, 249);
    padding: 4px;
    border-radius: 6px;
}