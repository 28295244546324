.code-execution-panel-container {
    width: 100%;
    height: 87vh;
    background-color: #f7f7f7;
    display: inline-block;
    border-left: 1px solid #e4e4e4;
    padding: 15px;
    padding-right: 30px;
}

.playgroundroom-navigation-top {
    background-color: #fafafa;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding-right: 20px;
    text-align: right;
    height: 100%;
}

.plaground-nav-icon {
    color: #696969;
    font-size: 20px;
}

.execution-output-container {
    color: rgb(105, 105, 105);
    background-color: #e7e7e7;
    width: 100%;
    height: 250px;
    padding: 10px;
    border-radius: 10px;
}

.execution-output-panel {
    width: 100%;
    height: 100%;
    background-color: #e7e7e7;
    color: rgb(122, 122, 122);
    overflow-x: hidden;
    overflow-y: hidden;
}

.playground-room-snackbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 250px;
    height: 40px;
    background-color: rgb(30, 113, 207);
    border-radius: 8px;
    color: white;
    font-size: 12px;
}

.online-participants-container {
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px rgb(218, 218, 218) solid;
    margin: 0;
    padding: 0;
}

.online-participant-card {
    cursor: pointer !important;
    margin: 3px;
    padding: 3px;
}

.playground-note-textarea {
    width: 100%;
    resize: none;
    border: 1px rgb(212, 212, 212) solid;
    border-radius: 6px;
    font-size: 13px;
    color: grey;
    padding: 5px;
}

.playground-note-textarea:focus {
    outline: none;
}

.playground-note-container {
    width: 100%;
    height: 100%;
    background-color: rgb(246, 246, 246);
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    color: grey;
    font-size: 13px;
}

.p-text-small{
    font-size: 12px;
    font-weight: 400;
    display: inline;
    padding-left: 5px;
    color:rgb(117, 117, 117);
}

.copy-clipboard-icon{
    color: grey;
    size: 25px;
    cursor: pointer;
}