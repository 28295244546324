.dashboard-pannel-container{
    width: 100%;
    height: 92vh;
    padding: 15px;
    background-color: #F3F4F9;
}

.dashboard-container{
    background-color: #F3F4F9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
