
.sidebar {
    margin: 15px 0px 15px 015px;
    width: 230px;
    height: 100vh;
    background-color:#102435;
    border-radius: 8px;
}   
.sidebar-toggled-on{
    padding: 10px;
    background-color: #102435;
    position: absolute;
    z-index: 10;
    width: 100%;;
}

.sidebar-item-text{
    color: white;
}

@media screen and (max-width: 1080px) {
    .sidebar {
        width: 50px !important ;
        transition: all 0.6s ease-out;
    }
    .logo-container{
        display: none !important;
    }
}
@media screen and (max-width:675px){
    .sidebar{
        display: none;
    }
}
@media screen and (min-width:675px){
    .sidebar-toggled-on{
        display: none;
    } 
}

.sidebar-icon{
    font-size: 20px;
    color: white;
}

.sidebar-item-active{
    background-color:  #233552;
    border-right: 5px solid #57a4e4;
}

.logo-container{
    width: 100%;
    height: 70px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}