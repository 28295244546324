.toolbar-container {
    width: 100%;
    height: 45px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
}

.toolbar-menu-item {
    display: none !important;
}

.toolbar-user-navigation {
    color: grey;
    font-size: 12px;
}

@media screen and (max-width:675px) {
    .toolbar-menu-item {
        display: inherit !important;
    }
}

.box-shadow{
    box-shadow: 2px 3px 14px -5px rgba(171,171,171,0.35) !important;
}