.code-editor-snippter{
    background-color: white;
    padding: 15px;
    height: 85vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow-y: scroll;
    overflow-y: scroll;
}

.code-snippet{
    width: auto;
    height: 300px;
    width: 400px;
    font-size: 13px !important;
    background-color: rgb(247, 247, 247) !important;
}

.code-snippet-container{
    margin: 15px;
    border: 1px rgb(241, 241, 241) solid;
    padding: 15px;
    border-radius: 10px;
}

.code-snippet-container-header{
    display: inline;
}

.code-snippet-active{
    border-color: rgb(140, 209, 250);
    background-color:  rgb(245, 245, 245);
    border-width: 1px;
    border-radius: 0px;
    cursor: pointer;
}

.code-snippet-active:hover{
    border: 1px rgb(241, 241, 241) solid;
    padding: 15px;
}

.code-snippet-selectable:hover{
    border-color: rgb(140, 209, 250);
    background-color:  rgb(245, 245, 245);
    border-width: 1px;
    border-radius: 0px;
    cursor: pointer;
}