.landing-page-navigation{
    width: 100%;
    height: 45px;
    background-color:#303F9F;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}
.landing-page-banner-row{
    width: 100%;
    height: 93vh;
    background-image: url('../../assets/images/code-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
}

.landing-page-banner-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.landing-page-banner-container h5{
    font-weight: 300;
    color: rgb(255, 255, 255);
}
.landing-page-banner-container h2{
    font-weight: 400;
    text-align: center;
    color: rgb(255, 255, 255);
}



.landing-page-icon-container p{
    font-weight: 300;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin: 0;
    align-self: center;    
}
.landing-page-icon-row{
    width: 40%;
    margin: auto;
}

.blue-separator{
    margin-top: 10px;
    width: 50px;
    height: 5px;
    background-color: #303F9F;
    border-radius: 10px;
    margin-bottom: 15px;
}

.landing-page-action-button{
    margin-top: 10px;
    width: 120px;
    background-color:transparent;
    border:2px solid #303F9F;
    color:#303F9F;
    height: 40px;
    border-radius: 6px;
}

.landing-page-action-button:hover{
    color: white;
}