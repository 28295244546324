.login-container{
    width: 100%;
    height: 100vh;
    background-color: rgb(241, 241, 241);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.login-form{
    width: 400px;
    margin: auto;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
}

.login-header{
    color: darkslategrey;
    padding-top: 15px;
    text-align: center;
    font-weight: 300;
}